<template>
    
    <div>

<div class="container">


<div class="row">

<div class="col-lg-6">
<h2>  Custom Website Widget  </h2>
    <p> Use a Goose widget on your site to direct your wholesale buyers to a dedicated landing page for your company </p>



<v-card  color=black dark >

    <v-card-text class="text-white">
{{copy+currentUser.company_relation+copy2}}     

    </v-card-text>

</v-card>

<p>  Copy and past the above code snippet to place your Goose Widget on your site to direct wholesale buyers to your Goose page!

</p>

</div>

<div class="col-lg-6">


  <v-card
    class="mx-auto "
    max-width="400"
  >

    <v-img
      class="white--text align-end"
      height="150px"
      gradient="to top ,  rgba(25,32,72,.9),rgba(100,115,201,.33)"

      src="https://storage.googleapis.com/goose_user_media/warehouse_small_7ac4b96f61/warehouse_small_7ac4b96f61.jpg
      "
    >
      <v-card-title class="display-1">Simple Wholesale <br> Online Ordering</v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0">
      <h4> {{company.profile.profile_company_name}} </h4>
    </v-card-subtitle>



    <v-card-text class="text--primary">
<div class="ml-10">
      <ul>
<li>  Fast approvals and no complicated credit apps.  </li>
<li>  Net 60 Terms  </li>
<li>  No Credit Card needed  </li>
<li>  Direct Vendor Pricing with no markups. 
  </li>
</ul>
</div>
     
    </v-card-text>

    <div class="text-center">
   <v-btn
    outlined
    class=""
    width="300"
    large
     target="_parent"
            currentUser.company_relation
            :href="
                                'https://www.goosedirect.com/vendor/' +
                                  $store.state.auth.user.company_relation +
                                  '/welcome'
                              "
            >
              <i class="sl sl-icon-check"></i> Order Wholesale
            </v-btn>

</div>


   


         <v-btn
        text
        color="blue accent-4"
        class="mt-3  justify-content-end"
      >
        Powered by Goose
      </v-btn>

  </v-card>

</div>




</div>




</div>


    <v-col cols="12" md="4">
                    <v-text-field
                      label="Company ID "
                      disabled
                      :value="currentUser.company_relation"
                    />
                  </v-col>


    </div>

</template>



<script>
import { mapGetters } from 'vuex'
import GetCompanyProfile from '@/graphql/queries/GetCompanyProfile.gql'


export default {
  name: 'widget',
  components: {
   
  },

  data() {
    return {
      copy: ` 
<iframe style="width:100%;height:460px; border:none" src="https://goosedirect.farm/links/`,

      copy2: `" scrolling="no">This browser does not support Iframe</iframe>

      `

    }
  },

  computed: {
    ...mapGetters(['currentUser']),

  },
    apollo: {
    company: {
      query: GetCompanyProfile,
      variables() {
        return {
          id: this.$store.state.auth.user.company_relation,
        }
      },
    },
  },
  



}
</script>
